import { useHistory } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import Page from "components/UI/Page/Page"
import { getRoutePath } from "routes"
import ContextQueryForm from "../components/ContextQueryFrom/ContextQueryForm"

import { useCreateContextQuery } from "resources/contextQueries/contextQueriesQueries"
import styles from "../components/commonStyles.module.scss"

export default function ContextQueriesCreate() {
  const history = useHistory()

  const { mutateAsync, isLoading } = useCreateContextQuery()

  return (
    <Page
      title="Create context query"
      headerContent={
        <div className={styles.buttons}>
          <Button
            color="grey"
            variant="outlined"
            onClick={() => history.push(getRoutePath("administration.contextQueries"))}
          >
            Cancel
          </Button>
          <Button form="contextQueryForm" type="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      }
    >
      <ContextQueryForm
        onSubmit={data =>
          mutateAsync(data, {
            onSuccess: ({ key }) =>
              history.push(getRoutePath("administration.contextQueries.detail", { key })),
          })
        }
      />
    </Page>
  )
}
