import { Redirect, useHistory, useParams } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
import {
  useFetchContextQuery,
  useModifyContextQuery,
} from "resources/contextQueries/contextQueriesQueries"
import { getRoutePath } from "routes"
import ContextQueryForm from "../components/ContextQueryFrom/ContextQueryForm"

import styles from "../components/commonStyles.module.scss"

export default function ContextQueriesDetail() {
  const history = useHistory()
  const { key } = useParams<{ key: string }>()

  const { data, isLoading, isError } = useFetchContextQuery(key)
  const { mutateAsync, isLoading: isSubmitting } = useModifyContextQuery()

  if (data === null) return <Redirect to={getRoutePath("not-found")} />
  if (isError) return null

  return (
    <Page
      title="Edit context query"
      headerContent={
        !isLoading && (
          <div className={styles.buttons}>
            <Button
              color="grey"
              variant="outlined"
              onClick={() => history.push(getRoutePath("administration.contextQueries"))}
            >
              Cancel
            </Button>
            <Button form="contextQueryForm" type="submit" loading={isSubmitting}>
              Save
            </Button>
          </div>
        )
      }
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <ContextQueryForm
          contextQuery={data}
          onSubmit={formData => mutateAsync({ data: formData, key })}
        />
      )}
    </Page>
  )
}
