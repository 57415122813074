import { OrderDir } from "types/util"
import { sanitizeDatetime } from "utilities/date"

// ramda ascend and descend doesn't sort date string when some of values are null
export const sortByDateProp = <T extends object>(
  items: Array<T>,
  orderDir: OrderDir,
  sortBy: keyof T,
) =>
  items.sort(({ [sortBy]: a }, { [sortBy]: b }) => {
    if (a === null && b === null) return 0
    if (a === null) return 1
    if (b === null) return -1
    if (a === b) return 0

    if (typeof a !== "string" || typeof b !== "string") return 0

    return sanitizeDatetime(a).getTime() < sanitizeDatetime(b).getTime()
      ? orderDir === "ASC"
        ? -1
        : 1
      : orderDir === "ASC"
      ? 1
      : -1
  })
