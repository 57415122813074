import { useHistory } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"

import Page from "components/UI/Page/Page"
import Paper from "components/UI/elements/Paper"
import Table from "components/Table/Table"
import { getColumns } from "./ContextQuriesList.config"
import { useFetchAllContextQueries } from "resources/contextQueries/contextQueriesQueries"
import {
  ContextQuery,
  ContextQueryFilterInitialState,
  ContextQueryFilterState,
} from "resources/contextQueries/contextQueriesTypes"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import create from "zustand"

const filtersInitialState: ContextQueryFilterInitialState = {
  sortBy: "last_run",
  sortDir: "DESC",
}

export const useFiltersStore = create<ContextQueryFilterState>(set => ({
  ...filtersInitialState,
  setSort: orderBy =>
    set(state => ({
      sortDir: state.sortBy === orderBy && state.sortDir === "ASC" ? "DESC" : "ASC",
      sortBy: orderBy,
    })),
}))

export default function MetaAttributesList() {
  const { sortBy, sortDir, setSort } = useFiltersStore()
  const history = useHistory()
  const { data, isLoading } = useFetchAllContextQueries({ sortBy, sortDir })

  return (
    <Page
      title="Context queries"
      headerContent={
        <Button onClick={() => history.push(getRoutePath("administration.contextQueries.create"))}>
          + Create
        </Button>
      }
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Paper>
          <Table<ContextQuery & { id: string }>
            columns={getColumns(setSort)}
            data={data?.map(d => ({ ...d, id: d.key })) ?? []} // the mapping to an id is done in order to sarisfy table data type
            sortBy={sortBy}
            sortDir={sortDir}
            emptyMessage="No context queries found."
          />
        </Paper>
      )}
    </Page>
  )
}
