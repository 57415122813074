export const colorQueryBySeparator = (
  definition: string,
  separator: string,
  color: `#${string}`,
) => {
  const splittedByQuotes = definition.split(separator)
  if (splittedByQuotes.length === 1) return definition

  let returnValue = ""
  splittedByQuotes.forEach((str, index) => {
    // SQL query can not start with single or double quotes - check of odd number
    if (index % 2)
      returnValue += `<span style="color: ${color};">${separator}${str}${separator}</span>`
    else returnValue += str
  })

  return returnValue
}
