import Tippy from "@tippyjs/react"
import { Column } from "components/Table/Table"
import Datetime from "components/UI/elements/Datetime/Datetime"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { Interweave } from "interweave"
import { pipe } from "ramda"
import { Link } from "react-router-dom"
import { ContextQuery, ContextQuerySort } from "resources/contextQueries/contextQueriesTypes"
import { getRoutePath } from "routes"
import { colorQueryBySeparator } from "utilities/query"

import styles from "./ContextQueriesList.module.scss"

type GetColumnsFnType = (setSort: (sortBy: ContextQuerySort) => void) => Column<ContextQuery>[]

export const getColumns: GetColumnsFnType = setSort => [
  {
    id: "key",
    label: "Key",
    renderCell: ({ key }) => <h4>{key}</h4>,
    onSort: () => setSort("key"),
  },
  {
    id: "description",
    label: "Description",
    renderCell: ({ description }) => description,
  },
  {
    id: "last_run",
    label: "Last run",
    renderCell: ({ last_run, value }) =>
      last_run ? (
        <div>
          <Datetime precision="minute" datetime={last_run} />
          <br />
          {value ? <div>({value})</div> : "-"}
        </div>
      ) : (
        <Tippy
          placement="bottom"
          content={
            <b>
              Value was not yet calcualted. <br /> This process occurs once a day
            </b>
          }
        >
          <div>
            —<br />—
          </div>
        </Tippy>
      ),
    onSort: () => setSort("last_run"),
  },
  {
    id: "modified",
    label: "Modified at",
    renderCell: ({ modified }) => <Datetime precision="minute" datetime={modified} />,
    onSort: () => setSort("modified"),
  },
  {
    id: "actions",
    renderCell: ({ query, key }) => {
      const coloredDefinition = pipe(
        def => colorQueryBySeparator(def, '"', "#4285F4"),
        def => colorQueryBySeparator(def, "'", "#ED382A"),
      )(query)

      return (
        <div className={styles.actionColumn}>
          <Tippy
            allowHTML
            interactive
            content={<Interweave content={coloredDefinition} className={styles.tooltipContent} />}
            placement="bottom"
            className={styles.tooltip}
          >
            <span>
              <IconButton
                className={styles.preview}
                color="black"
                icon="comment-code"
                size="xs"
                variant="outlined"
              />
            </span>
          </Tippy>
          <Link to={getRoutePath("administration.contextQueries.detail", { key })}>
            <IconButton tooltip="Edit" color="black" icon="pencil" size="xs" variant="outlined" />
          </Link>
        </div>
      )
    },
  },
]
