import requestFactory from "api/request"
import {
  ContextQuery,
  ContextQueryCreatePayload,
  ContextQueryListResponse,
  ContextQueryModifyPayload,
  ContextQueryResponse,
} from "./contextQueriesTypes"

const contextQueries = {
  list(): Promise<ContextQueryListResponse> {
    return requestFactory("GET", "/context_queries")
  },
  retrieve(key: ContextQuery["key"]): Promise<ContextQueryResponse> {
    return requestFactory("GET", `/context_queries/${key}`)
  },
  modify({ key, data }: ContextQueryModifyPayload): Promise<ContextQueryResponse> {
    return requestFactory("PATCH", `/context_queries/${key}`, data)
  },
  create(data: ContextQueryCreatePayload): Promise<ContextQueryResponse> {
    return requestFactory("POST", "/context_queries", data)
  },
}

export default contextQueries
