import { Route, Switch } from "react-router-dom"

import { getRoutePath } from "routes"
import ContextQueriesCreate from "./ContextQueriesCreate/ContextQueriesCreate"
import ContextQueriesDetail from "./ContextQueriesDetail/ContextQueriesDetail"
import ContextQueriesList from "./ContextQueriesList/ContextQueriesList"

export default function ContextQueriesSetup() {
  return (
    <Switch>
      <Route
        path={getRoutePath("administration.contextQueries")}
        exact
        component={ContextQueriesList}
      />
      <Route
        path={getRoutePath("administration.contextQueries.create")}
        component={ContextQueriesCreate}
      />
      <Route
        path={getRoutePath("administration.contextQueries.detail")}
        component={ContextQueriesDetail}
      />
    </Switch>
  )
}
