import classNames from "classnames"
import { pick } from "ramda"
import { lazy, Suspense } from "react"
import { Controller, useForm } from "react-hook-form"
import { Prompt } from "react-router-dom"

import Paper from "components/UI/elements/Paper"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { maxLength, pythonVariable, required } from "helpers/validators.helper"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import TextArea from "components/UI/elements/TextArea/TextArea"
import { ContextQuery } from "resources/contextQueries/contextQueriesTypes"
import styles from "./ContextQueryForm.module.scss"
const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))

type FormValues = Pick<ContextQuery, "key" | "query" | "description">

type Props = {
  onSubmit: (data: FormValues) => Promise<ContextQuery>
  contextQuery?: ContextQuery
}

export default function ContextQueryForm({ contextQuery, onSubmit }: Props) {
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty, isSubmitting, isSubmitted },
  } = useForm<FormValues>({
    defaultValues: contextQuery && pick(["key", "query", "description"], contextQuery),
  })

  const submit = async (formValues: FormValues) => {
    await onSubmit(formValues)
    reset(formValues)
  }
  return (
    <form id="contextQueryForm" onSubmit={handleSubmit(submit)}>
      <Prompt
        when={isDirty && !isSubmitting && !isSubmitted}
        message="Changes you made will not be saved."
      />

      <Paper hasHeader className={styles.paper}>
        <div className={styles.row}>
          <div className={styles.left}>
            <h2>General</h2>
          </div>
          <div className={styles.id}>
            <TextInput
              {...register("key", {
                validate: { pythonVariable, required, maxLength: maxLength(255) },
                disabled: !!contextQuery,
              })}
              disabled={!!contextQuery}
              error={errors.key?.message}
              label="Context Query Key"
              placeholder="Context Query Key"
            />
          </div>
        </div>
        <div className={classNames(styles.row, styles.grey)}>
          <div className={styles.left}>
            <h2>Description</h2>
          </div>
          <div className={styles.right}>
            <TextArea
              {...register("description")}
              label="Description"
              placeholder="Description"
              rows={10}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>
            <h2>Definition</h2>
          </div>
          <div className={styles.right}>
            <Controller
              control={control}
              name="query"
              rules={{ validate: { required } }}
              render={({ field: { onChange, value } }) => (
                <div className={styles.aceEditorWrapper}>
                  <label>SQL query</label>
                  <Suspense fallback={<LoadingIndicator />}>
                    <AceEditor
                      wrapEnabled
                      showPrintMargin={false}
                      className={classNames(styles.aceEditor, {
                        [styles.error]: !!errors.query?.message,
                      })}
                      editorProps={{ $blockScrolling: true }}
                      mode="pgsql"
                      height="400px"
                      theme="github"
                      width="100%"
                      value={value}
                      onChange={onChange}
                      errorMessage={errors.query?.message}
                    />
                  </Suspense>
                </div>
              )}
            />
          </div>
        </div>
      </Paper>
    </form>
  )
}
