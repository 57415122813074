import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { api } from "api"
import { showToast } from "app/toast"
import { prop, sort } from "ramda"
import { sortByDateProp } from "resources/utils"
import { ascend, descend } from "utilities/comparators"
import {
  ContextQuery,
  ContextQueryCreatePayload,
  ContextQueryFilterInitialState,
  ContextQueryListResponse,
  ContextQueryModifyPayload,
} from "./contextQueriesTypes"

const CONTEXT_QUERY = "contextQuery" as const
const CONTEXT_QUERY_LIST_OK: QueryKey = [CONTEXT_QUERY, "list"]

function useFetchContextQueries<T = ContextQueryListResponse>(
  config?: UseQueryOptions<ContextQueryListResponse, unknown, T, QueryKey>,
) {
  return useQuery(CONTEXT_QUERY_LIST_OK, api.contextQuery.list, config)
}

export const useFetchAllContextQueries = (options?: ContextQueryFilterInitialState) =>
  useFetchContextQueries({
    select: responseData => {
      if (!options) return responseData

      let sortedContextQueries: ContextQuery[] = []

      if (options.sortBy === "last_run" || options.sortBy === "modified") {
        sortedContextQueries = sortByDateProp(responseData, options.sortDir, options.sortBy)
      } else {
        const comparator = options.sortDir === "ASC" ? ascend : descend
        sortedContextQueries = sort(comparator(prop(options.sortBy)), responseData)
      }

      return sortedContextQueries
    },
  })

export const useFetchContextQuery = (key: ContextQuery["key"]) =>
  useQuery([CONTEXT_QUERY, key], () => api.contextQuery.retrieve(key))

export const useModifyContextQuery = () => {
  const queryClient = useQueryClient()

  return useMutation((payload: ContextQueryModifyPayload) => api.contextQuery.modify(payload), {
    onSuccess: response_context_query => {
      queryClient.setQueryData<ContextQueryListResponse>(CONTEXT_QUERY_LIST_OK, cachedList => {
        if (!cachedList) return

        const contextQueryToUpdateIndex = cachedList.findIndex(
          ({ key }) => key === response_context_query.key,
        )

        cachedList[contextQueryToUpdateIndex] = response_context_query

        return cachedList
      })

      queryClient.setQueryData([CONTEXT_QUERY, response_context_query.key], response_context_query)
      showToast("Context query modified")
    },
  })
}

export const useCreateContextQuery = () => {
  const queryClient = useQueryClient()

  return useMutation((payload: ContextQueryCreatePayload) => api.contextQuery.create(payload), {
    onSuccess: response_context_query => {
      queryClient.setQueryData<ContextQueryListResponse>(CONTEXT_QUERY_LIST_OK, cachedList => {
        if (!cachedList) return

        cachedList.push(response_context_query)

        return cachedList
      })
      queryClient.setQueryData([CONTEXT_QUERY, response_context_query.key], response_context_query)
      showToast("Context query created")
    },
  })
}
